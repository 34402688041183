import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/pgegenfurtner/Projekte/interness/atacama/atacama-packages/packages/web-core/src/templates/mdx/default.js";
import CallToAction from '@interness/web-core/src/components/modules/CallToAction/CallToAction';
import HeaderImg from '../../components/HeaderImg';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const HeaderImage = makeShortcode("HeaderImage");
const Wrapper = makeShortcode("Wrapper");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <HeaderImage mdxType="HeaderImage">
    <HeaderImg id="chiropraktik" mdxType="HeaderImg" />
    </HeaderImage>
    <hr></hr>
    <Wrapper mdxType="Wrapper">
      <h1>{`Chiropraktik`}</h1>
      <h3 {...{
        "id": "chiropraktik"
      }}>{`Chiropraktik`}</h3>
      <p>{`Die Chiropraktik ist eine manuelle Therapieform, bei der mit speziellen Handgrifftechniken Gelenke und die Wirbelsäule behandelt werden. Es gibt zahlreiche unterschiedliche Techniken: von der schonenden Dehnung über Mobilisierungstechniken bis hin zum Reponieren („Einrenken“).`}</p>
      <p>{`Die Anwendungsgebiete sind z.B. Knie-, Hüft- und `}<a parentName="p" {...{
          "href": "/beschwerdebilder/rueckenbeschwerden"
        }}>{`Rückenbeschwerden`}</a>{` durch Beckenverdrehungen (erworbene Beinlängendifferenz), Wirbelverklebungen und -verrenkungen, Schultersteife, Kopfschmerzen, Ohrgeräusche u.a.m..`}</p>
      <p>{`In unserer Praxis kommen überwiegend die Techniken nach ACKERMANN zum Einsatz, denen im Prinzip vor dem Reponieren eine Dehnungsphase vorausgeht. Die Anwendungen sind somit sehr Gelenkflächen schonend und verträglich.`}</p>
      <p>{`Bei den meisten chronischen Rücken- und Gelenkbeschwerden ist die Chiropraktik Teil eines Therapiepaketes:`}</p>
      <p>{`Durch chronische Schmerzen/Neuralgien, Fehlstellungen, `}<a parentName="p" {...{
          "href": "/beschwerdebilder/durchblutungstoerung"
        }}>{`Durchblutungsstörungen`}</a>{`, `}<a parentName="p" {...{
          "href": "/beschwerdebilder/arthrose_rheuma"
        }}>{`Arthrosen / Rheuma`}</a>{` können neben den ursprünglich betroffenen Gelenken und Wirbelsäulenabschnitten ganze Köperregionen in Mitleidenschaft gezogen sein, die eine Begleitbehandlung mit `}<a parentName="p" {...{
          "href": "/therapien/schroepfmassage"
        }}>{`Schröpfmassage`}</a>{`, `}<a parentName="p" {...{
          "href": "/therapien/neuraltherapie"
        }}>{`Neuraltherapie`}</a>{`, `}<a parentName="p" {...{
          "href": "/therapien/injekto-akupunktur"
        }}>{`Injekto-Akupunktur`}</a>{` und/oder die Einnahme von Medikamenten aus der Phytotherapie (pflanzliche Mittel) und `}<a parentName="p" {...{
          "href": "/therapien/homoeopathie"
        }}>{`Homöopathie`}</a>{` notwendig machen.`}</p>
      <hr></hr>
      <div style={{
        textAlign: 'center'
      }}>
    <CallToAction mdxType="CallToAction" />
      </div>
      <hr></hr>
    </Wrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      